import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';
import MaskedInput from 'react-text-mask'
const RegisterDetails = ({ next, warrantydetails, serialID, productID, companyID }) => {
  let deviceID =
    new URLSearchParams(window.location.search).get("deviceID") || new URLSearchParams(window.location.search).get("device") || "";

  const uniqueIDUrl = deviceID.slice(0, 12) || "";
  const serialIDUrl = deviceID.slice(12, 25) || "";
  const [inputProductId, setInputProductID] = useState(uniqueIDUrl || "");
  const [warrantyResponse, setWarrantyResponse] = useState(warrantydetails);
  const [phoneInputState, setPhoneInputState] = useState(false);
  const [phoneInput, setPhoneInput] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [data, setData] = useState({});
  const [dataMessage, setDataMessage] = useState("");
  const [ip, setIp] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [firmwareInput, setFirmwareInput] = useState();
  const [update, setUpdate] = useState();
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [progressIndication, setProgressIndication] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_GEOLOCATION_URL}/json/`
    );

    setIp(res.data.IPv4);
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const getSerialID = serialID;
  // const getProductID = productID;
  const getProductID = inputProductId;
  const getIds = `${getProductID}${getSerialID}`;
  const onSubmit = () => {
    setProgressIndication(true);
    const requestedOptions = {
      method: "PUT",
      body: JSON.stringify({
        deviceId: getIds,
        name: inputName,
        email: inputEmail,
        phone: phoneInput,
        serial: serialID,
        ip: ip,
        softwareUpdateNotifications: firmwareInput,
        promotionalEmails: update,
        company_id: 1,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${process.env.REACT_APP_API_URL}/warranty`, requestedOptions)
      .then((res) => res.json())
      .then((result) => {
        //console.log("result", result);
        setData(result);
        setProgressIndication(false);
        setDataMessage(result.status);
        setLoading(false);
        if (warrantydetails.pop_applicable_but_missing) {
          const formData = new FormData();
          formData.append("purchaseDate", date);
          formData.append("proof", selectedFile);
          formData.append("serial", getSerialID);
          fetch(`${process.env.REACT_APP_API_URL}/uploadProofOfPurchase`, {
            body: formData,
            method: "POST",
          }).then((res) => res.json())
            .then((result) => {
              if (result.status == 'success') {
                warrantydetails.pop_applicable_but_missing = false;
              }
              if (result.status == 'failed') {
                warrantydetails.pop_applicable_but_missing = false;
              }
            }).catch(e => console.log(e))
        }
        if (!companyID) {
          companyID = process.env.company_ID
        }

        const requestOptionsPost = {
          method: "POST",
          body: JSON.stringify({
            serial: serialID,
            unique_id: inputProductId,
            company_id: 1,
          }),
          //  mode: 'no-cors',
          headers: {
            "Content-Type": "application/json",
          },
        };

        fetch(`${process.env.REACT_APP_API_URL}/warranty`, requestOptionsPost)
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.status === "failed") {
                return false;
              }
              if (result.data.warrantyDetails) {
                const resultdata = result.data.warrantyDetails;
                setWarrantyResponse(resultdata);
              }
            },
            (error) => {
              console.log(error);
            }
          );

      }).catch(e => console.log(e))
  };
  const hideserialvalidation_text = () => {
    document.getElementById('serialvalidation').className = 'd-none';
  }

  const routeToHome = () => {
    history.push("/warranty");
  };

  const handleChange = (event) => {
    setPhoneInputState(!phoneInputState);
  };

  return (
    <>
      {getSerialID === "" && false ? (
        <div className="card-body p-4 text-center">
          {" "}
          <h2
            style={{
              marginTop: "100px",
              marginBottom: "50px",
              textAlign: "center",
            }}
          >
            {" "}
            Error.... <br /> Product details not verified
          </h2>
          <button onClick={routeToHome} className="btn btn-primary  btn-lg  ">
            {" "}
            Click here to verify{" "}
          </button>
        </div>
      ) : (
        <>
          {/* {!progressIndication ? (
                          <h2
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="spinner-border"
                              style={{ width: "3rem", height: "3rem" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </h2>
                          ) : ''}   */}
          {(!data.otp && data.status !== "success" && data.status !== "failed") ? (
            // <form onSubmit={()=>{handleSubmit(onSubmit);}}>         
            <form onSubmit={() => { handleSubmit(onSubmit) }}>
              {/* <div className="card border-0 mt-3 mb-5"></div> */}
              <div className="mb-4 custom-styles-col row">
                <div className="col-lg-10 offset-lg-1">
                  {/* <div className="card-header bg-transparent py-4">
                            <div className="d-flex justify-content-between">
                              <h2 className="lead mb-0">
                                Enter Your Personal Details
                              </h2>
                            </div>
                          </div>                           */}
                  <div className="card-body p-4">
                    <div className="row ">
                      <div className="col-md-12">
                        {/* <div className="">                                                                                                  */}
                        {     //new RegExp(process.env.REACT_APP_DISABLE_INTERNAL_ID) - To Do need to update regex from env file here.
                          !(/\b730\S+|\A5A+/g.test(serialID)) ?

                            <div className=" mb-3" data-toggle="tooltip" title={!next ? 'Enter serial number field first' : 'Enter product id'}>
                              <OverlayTrigger
                                placement="bottom"
                                trigger="click"
                                overlay={(
                                  <Popover>
                                    <Popover.Title as="h3">
                                      Find Product Id
                                    </Popover.Title>
                                    <Popover.Content>
                                      <span> If available, your Product ID number will appear when your device prompts you for warranty activation.       </span>
                                      <br />
                                      <br />
                                      <span>This will appear as a series of 12 digits in pairs of two e.g. AA BB CC DD EE FF.     </span>
                                      <br />
                                      <br />
                                      <small className="text-muted">If you are unable to find the Product Id, leave the input box empty and register the warranty without it.     </small>
                                    </Popover.Content>
                                  </Popover>
                                )}>
                                <label htmlFor="floatingInput" className="text-muted">
                                  <small>Unique Product Id {uniqueIDUrl ?
                                    <>
                                      <span className='text-dark fw-bold'>(Populated by QR)</span>
                                    </> : null}
                                  </small>
                                  <i variant="success" class="fas fa-question-circle"></i>
                                </label>

                              </OverlayTrigger>

                              <MaskedInput
                                disabled={!next || document.getElementById('product_id').value.length == 12}
                                type="text"
                                name="product_id"
                                className={
                                  "form-control py-4"
                                }
                                id="product_id"
                                placeholder="AABBCCDDEEFF (12 digits)"
                                mask={[/[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/,  '-', /[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/, '-', /[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/, /[A-F0-9a-f]/]}
                                //maxLength="12"
                                required=""
                                {...register("product_id")}
                                value={inputProductId}
                                onChange={(e) => {
                                  const input = e.currentTarget.value.replace(/[^A-Z0-9]+/ig, "");
                                  console.log(input)
                                  if (
                                    /^[0-9a-f-A-F]+$/.test(input) ||
                                    /^[0-9A-F]+$/.test(input) ||
                                    input === ""
                                  ) {
                                    setInputProductID(input);
                                  }
                                }}
                              />
                              <span className="text-danger">
                                <span>
                                  {!(inputProductId == '' || inputProductId.length >= 12) && (
                                    <span>
                                      The Product ID seems invalid.Go to your<span className="text-primary"> {`Menu > settings > Activate and type the code displayed.`}</span> If you are unsure, leave this field blank and we will register without sending you an activation code.
                                    </span>
                                  )}
                                </span>
                              </span>

                            </div>
                            : null
                        }


                        <div className="mb-3">
                          <label
                            htmlFor="floatingInput"
                            className="text-muted"
                          >
                            <small>Your Name</small>
                          </label>
                          <input
                            disabled={!next}
                            type="text"
                            {...register("name", {
                              required: true,
                            })}
                            name="name"
                            className="form-control  py-4"
                            id="name"
                            placeholder="Name"
                            required=""
                            value={inputName}
                            onChange={(e) => {
                              setInputName(e.target.value);
                            }}
                          />

                          <span className="text-danger">
                            <span>
                              {errors.name &&
                                errors.name.type === "required" && (
                                  <span>Name is required</span>
                                )}
                            </span>
                          </span>
                        </div>
                        <div className=" mb-3">
                          <label
                            htmlFor="floatingInput"
                            className="text-muted"
                          >
                            <small>Your Email</small>
                          </label>
                          <input
                            disabled={!next}
                            {...register("email", {
                              required: true,
                            })}
                            type="email"
                            className="form-control py-4"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email"
                            required=""
                            value={inputEmail}
                            onChange={(e) => {
                              setInputEmail(e.target.value);
                            }}
                          />

                          <span className="text-danger">
                            <span>
                              {errors.email &&
                                errors.email.type === "required" && (
                                  <span>Email is required</span>
                                )}
                            </span>
                          </span>
                        </div>
                        {warrantydetails.pop_applicable_but_missing ?
                          <>
                            <div className=" mb-3">
                              <label
                                htmlFor="floatingInput"
                                className="text-muted"
                              >
                                <small>Upload Proof Of Purchase</small>
                              </label>
                              <input
                                disabled={!next}
                                // {...register("file", {
                                //   required: true,
                                // })}
                                type="file"
                                accept=" .png, .jpeg, .jpg, .pdf, .docx, .doc, .gif,.bmp "
                                max-size="10000"
                                className="form-control"
                                id="file"
                                name="file"
                                placeholder="Upload a file"
                                required=""
                                // value={ selectedFile}
                                onChange={onFileChange}
                              />
                              <span className="text-danger">
                                <span>
                                  {errors.file &&
                                    errors.file.type === "required" && (
                                      <span>Need to upload the file</span>
                                    )}
                                </span>
                              </span>
                            </div>
                            <div className=" mb-4">
                              <label
                                htmlFor="floatingInput"
                                className="text-muted"
                              >
                                <small>Date Of Purchase</small>
                              </label>
                              <input
                                disabled={!next}
                                // {...register("date", {
                                //   required: true,
                                // })}
                                data-date-inline-picker="true"
                                type="date"
                                className="form-control py-4"
                                id="date"
                                name="date"
                                placeholder=""
                                required=""
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              // value={ inputEmail}
                              // onChange = { (e) => { setInputEmail(e.target.value)} }
                              />
                              <span className="text-danger">
                                <span>
                                  {errors.date &&
                                    errors.date.type === "required" && (
                                      <span>Select the date</span>
                                    )}
                                </span>
                              </span>
                            </div>
                          </>
                          : ''}

                        {/* <div className="form-check ms-sm-2 ms-2">
                                    <input
                                      className="form-check-input fs-1 mt-0"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      value={phoneInputState}
                                      onClick={handleChange}
                                    />
                                    <label
                                      className="form-check-label mb-0"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Send me an SMS.
                                    </label>
                                  </div> */}
                        {/* </div> */}
                        <small className="d-block text-center text-dark fw-bold">
                          We hate spam and we respect your privacy
                        </small>
                        {phoneInputState && (
                          <div className="mb-3">
                            <label
                              htmlFor="floatingInput"
                              className="text-muted"
                            >
                              <small>Your Phone</small>
                            </label>
                            <input
                              disabled={!next}
                              {...register("phone", {
                                required: true,
                              })}
                              className="form-control"
                              type="text"
                              placeholder="Enter Your Phone"
                              id="phone"
                              name="phone"
                              value={phoneInput}
                              onChange={(e) =>
                                setPhoneInput(e.target.value)
                              }
                            />
                            <span className="text-danger">
                              <span>
                                {errors.phone &&
                                  errors.phone.type === "required" && (
                                    <span>Phone is required</span>
                                  )}
                              </span>
                            </span>
                          </div>
                        )}

                        <div className="mt-5 mb-4">
                          <div className="form-check form-switch mb-2 ms-3">
                            <input
                              disabled={!next}
                              className="form-check-input"
                              type="checkbox"
                              id="updates"
                              value={firmwareInput}
                              onChange={(event) => setFirmwareInput(1)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="updates"
                            >
                              Receive firmware updates
                            </label>
                          </div>
                          <div className="form-check form-switch mb-2 ms-3">
                            <input
                              disabled={!next}
                              className="form-check-input"
                              type="checkbox"
                              id="offers"
                              value={update}
                              onChange={(event) => setUpdate(1)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="offers"
                            >
                              Receive special offers just for you
                            </label>
                          </div>
                        </div>
                        <div
                          data-toggle='tooltip'
                          title={!next ? 'fill all the required fields before submit' : 'Submit'}
                          className="mt-5"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            disabled={!next}
                            className="btn btn-primary  btn-lg"
                            data-bs-toggle="collapse"
                            // href="#collapseExample"
                            // role="button"
                            type='submit'
                            onClick={handleSubmit(onSubmit)}
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            {!(/\b730\S+|\A5A+/g.test(serialID)) ? 'Get Verification Code' : 'Register Now'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )

            :

            <>
              {
                data.status == 'success' ?
                  <>
                    <h2 className="text-center text-success">{`Thank you for submitting your information. Warranty registration was successful.`}</h2>
                    {data.otp && data.otp != '' ? <h2 class="text-center text-success">Your Verification Code is <strong><u>{data.otp}</u></strong></h2> : <h2></h2>}</> :
                  <h2 className="text-center text-danger">{`device registration ${data.status}`}</h2>
              }

              {hideserialvalidation_text()}
            </>
          }

          {progressIndication ? (
            <h2
              style={{
                textAlign: "center",
              }}
            >
              <div
                className="spinner-border"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </h2>
          ) : ''}
        </>
      )}{" "}
    </>
  );
};

export default RegisterDetails;
