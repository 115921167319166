import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
import "./Home.css";
import WarrantyAndSupport from "./WarrantyAndSupport";
import Header from "./Header";
import Footer  from "./Footer";
import RegisterDetails from "./RegisterDetails"

const Home = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let deviceID =
    new URLSearchParams(window.location.search).get("deviceID") || new URLSearchParams(window.location.search).get("device") || "";

  const uniqueIDUrl = deviceID.slice(0, 12) || "";
  const serialIDUrl = deviceID.slice(12, 25) || "";
  
  // const referrerUrl = document.referrer || "smallhd.com";
 const referrerUrl = "smallhd.com";
 const [action,setAction]=useState('Go')
  const [inputSerialNumber, setInputSerialNumber] = useState(serialIDUrl || "");
  const [inputProductId, setInputProductID] = useState(uniqueIDUrl || "");
  const [referrerUrlDomain, setreferrerUrl] = useState(referrerUrl);
  const [warrantyResponse, setWarrantyResponse] = useState([]);
  const [failMessage, setFailMessage] = useState(true);
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validations, setValidations] = useState(
    deviceID === "" ? false : true
  );
  const [fail, setFail] = useState("");
  let [companyId, setCompanyId] = useState(0);
  const [productIdReq, setProductIdReq] = useState(true);
  const [regex,setRegex]=useState(new RegExp('//'))

  const [progressIndication, setProgressIndication] = useState(false);
  //const [serialNumberReq, setSerialNumberReq] = useState(true);
//for direct link registration
  useEffect(() => {
   
   try{  
    if((!next && companyId==0)){
      fetch(`${process.env.REACT_APP_API_URL}/getregexlist?company_id=${companyId==0?1:companyId}`)
    .then((res)=>res.json())
    .then((result)=>{
      setRegex(new RegExp(result.regex))
    })
    .catch(e=>console.log(e))
    
     if(inputSerialNumber.length==13){
      if( window.location.search.includes('deviceID')|| window.location.search.includes('device')){
        let pattern=regex
        if(inputSerialNumber.length==13 && pattern.test(inputSerialNumber)){

          onSubmit()
        }
       }
       else{
              let pattern=regex
              if(pattern.test(inputSerialNumber)){
                document.getElementById('serialnumberid').className+=' is-valid';
                if(companyId==0){
                  setCompanyId(process.env.REACT_APP_COMPANYID)
                }
                onSubmit()
              }
            }
     }
        }   
        else{
          if(inputSerialNumber.length==13){
            if( window.location.search.includes('deviceID')|| window.location.search.includes('device')){
              let pattern=regex
              if(inputSerialNumber.length==13 && pattern.test(inputSerialNumber)){
               console.log(1)
                onSubmit()
              }
             }
             else{
                    let pattern=regex
                    if(pattern.test(inputSerialNumber)){
                      document.getElementById('serialnumberid').className+=' is-valid';
                      if(companyId==0){
                        setCompanyId(process.env.REACT_APP_COMPANYID)
                      }
                      onSubmit()
                    }
                  }
           }
        }
    }catch(e){
      console.log(e)
    }
  }, [inputSerialNumber,regex]);

if(!companyId){
  fetch(`${process.env.REACT_APP_API_URL}/company?domain=${referrerUrlDomain}`)
  .then((res) => res.json())
  .then(
    (result) => {                                       
        if (result.status=='success') {              
          setCompanyId(result.data.company_id);
          setProductIdReq(result.data.internal_id_required);              
          //setSerialNumberReq(result.data.serial_number_required);                         
        }           
    },
    (error) => {
      console.log(error);
    }
  );
}  


  const onSubmit = (event) => {
    if(document.getElementById('serialnumberid').value==''){
      setAction('Go')
    }else{
      setAction('Reset')
    }
    setProgressIndication(true);
    if(!companyId || companyId==0){
      setCompanyId(`${process.env.REACT_COMPANY_ID}`)
    }
    const getIds=inputSerialNumber+inputProductId;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        serial: inputSerialNumber.toUpperCase(),
        test:companyId=='undefined',
        company_id: companyId==0 || companyId=='undefined' || companyId=='undefined'?process.env.REACT_APP_COMPANYID:companyId,
        unique_id: inputProductId,
        deviceId:getIds.toUpperCase()
      }),
      //  mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${process.env.REACT_APP_API_URL}/warranty`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
            if (result.status === "failed") {
              setFail(result.message);
              setFailMessage(true);  
              setProgressIndication(false);          
              return false;
            }                                        
            if (result.data.warrantyDetails) {
             if(result.data.warrantyDetails.base_item_name){
              const resultdata = result.data.warrantyDetails;              
              setWarrantyResponse(resultdata);              
              setNext(true);
              setLoading(false);
              setProgressIndication(false); 
             } 
             else {              
              setFail('serial base error');
              setFailMessage(true);  
              setProgressIndication(false); 
            }    
            }         
        },
        (error) => {
          console.log(error);
        }
      ).catch(e=>console.log(e))
  };
  let product = fail.includes("product");
  let serial = fail.includes("serial");

  const submitFormOnEnterKey=(event)=> {
    if (event.keyCode === 13) {      
      event.preventDefault();
      onSubmit();
    }
  }

  const handleClick = (event) => event.target.select();
  function playVideo()
  {
      document.getElementById("vid").play();
  }
  
  return (
    <>
    <div className="canvas">
    <Header />    
    <div className="content pt-5">
      <div className="detail">
        <div className="container-fluid p-0">
          <div className="repair-form">
          <div className="banner bg-light py-5">
              <div className="container">   
              <div className="row">
                <div className="col-lg-10 offset-lg-1">                     
                <h1 className="mb-0">
                  Warranty Registration{" "}
                </h1>
                </div></div>
              </div>
            </div>
            <div className="container">
              <div className="custom-styles-col">
     
          <form onSubmit={handleSubmit(onSubmit)}>  
                    
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                      <div className=" border-0">
                        {/* <div className="bg-transparent py-4">
                          <div className="d-block">
                            <h2  className="border-bottom pb-4 mb-2">Verify your product details</h2>
                          </div>
                        </div> */}
                        
                          <div className="Validated mt-3 row">
                            <div className="col-md-12">
                              <div className="card-body p-4 row align-items-start">

                              {/* {productIdReq && (

                            <div className=" mb-3 col-md-6">
                            <label htmlFor="floatingInput" className="text-muted">
                            <small>Unique Product Id</small>
                            </label>
                            <input
                            type="text"
                            name="product_id"
                            className={
                            "form-control"
                            }
                            id="product_id"
                            placeholder="Enter Your Product Id"
                            maxLength="12"
                            required=""
                            {...register("product_id")}
                            value={inputProductId}
                            onChange={(e) => {
                            const input = e.currentTarget.value;
                            if (
                              /^[0-9a-f-A-F]+$/.test(input) ||
                              /^[0-9A-F]+$/.test(input) ||
                              input === ""
                            ) {
                              setInputProductID(input);
                            }
                            }}
                            />
                            <span className="text-danger">
                            <span>
                            {errors.product_id &&
                              errors.product_id.type ===
                                "required" && (
                                <span>Please enter product Id</span>
                              )}
                            </span>
                            </span> 
                            <span className="text-danger">
                            <span>
                            {fail.includes("unique")
                              ? "Invalid Product Id"
                              : ""}
                            </span>
                            </span>
                            </div>
                            )} */}

                                <div className="col-lg-10">
                                  <label
                                    htmlFor="floatingInput"
                                    className="text-muted"
                                  >
                                    <small>Enter your product serial number</small>  <a className="ques-btn text-muted" data-bs-toggle="modal" data-bs-target="#serial-help" title="Where is my monitor serial located?" onClick={playVideo}><i class="fas fa-question-circle"></i></a>
                                  </label>
                                  <input
                                    disabled={next}
                                    {...register("serialnumberid", {
                                      required: true,
                                    })}
                                    type="text"
                                    name="serialnumberid"
                                    className={
                                    
                                      `${!next?'':'is-valid '}custom-input ${
                                        validations
                                        ? serial
                                          ? "form-control py-4 "
                                          : "form-control py-4 "
                                        : "form-control py-4"}`
                                    }
                                    id="serialnumberid"
                                    maxLength="13"
                                    placeholder="Serial Number"
                                    required=""
                                    value={inputSerialNumber}
                                    onChange={(e) =>{
                                      setInputSerialNumber(e.target.value);
                                    }
                                    }
                                    onKeyDown={(e) => submitFormOnEnterKey(e) }
                                    onClick={handleClick}
                                  />

                                  <span className="text-danger">
                                    <span>
                                      {errors.serialnumberid &&
                                        errors.serialnumberid.type ===
                                          "required" && (
                                          <span>
                                            Please enter serial number
                                          </span>
                                        )}
                                    </span>
                                  </span>
                                  <span className="text-danger">
                                    <span>
                                      {fail.includes("serial")
                                        ? <span id='serialvalidation'>
                                        <p className="mt-2">This is not a valid serial. Please try again. If this continues to happen</p>
                                         <a href='https://smallhd.com/pages/contact-us'>please reach out to our support team.</a></span>
                                        :next?<span id='serialvalidation'  className='text-success'>
                                        <p className="mt-2">Serial number is valid. Please fill in the remaining information below to register your warranty.</p>
                                        </span>:''}
                                    </span>
                                  </span>
                                </div>
                                <div className="col-lg-2 mb-lg-3 " id='refresh' onClick={()=>{
                                  if(action=='Reset'){
                                    setNext(false);
                                  window.location.replace(window.location.origin+window.location.pathname)
                                  }
                                  }}
                                  >
                                  <label htmlFor="floatingInput" className="text-muted">
                                    <small>&nbsp;</small>
                                    </label>
                                    <button type="submit" className="btn btn-primary w-100 py-4 btn-lg">
                                    {action=='Go'?null:<i className="fas fa-redo"></i>}
                                    <span> {action}</span>
                                      </button></div>

                                {/* <div className="col-lg-4 mb-lg-3 ">
                                <label
                                    htmlFor="floatingInput"
                                    className="text-muted"
                                  >
                                    <small>&nbsp;</small>
                                  </label>
                                <button
                                type="submit"
                                className="btn btn-primary w-100 py-4 btn-lg"
                              >
                                {progressIndication ? (
                                <span
                              className="spinner-border me-2"
                              style={{ width: "1.5rem", height: "1.5rem" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </span>) : null}
                             Verify Serial Number
                                
                              </button>
                                </div> */}
                              </div>
                              <div
                                className="justify-center"
                                style={{ textAlign: "center" }}
                              >
                              {progressIndication?<div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>:null}

                              </div>
                              
                            </div>
              
                          </div>
                        
                      </div> 
              </div>
            </div>   
            <RegisterDetails
              next={next}         
              warrantydetails={warrantyResponse}
              serialID={inputSerialNumber}
              productID={inputProductId}
              companyID={companyId==0 || companyId==undefined?process.env.REACT_APP_COMPANYID:companyId}
            />                
          </form>        
          
      </div>
      
                </div>
                {/* <Footer /> */}
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="serial-help" tabindex="-1" aria-labelledby="serial-helpLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="serial-helpLabel">SERIAL NUMBER</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <video id="vid" className="w-100 h-100" autoplay muted loop controls>
              <source src="../../images/Cine7-SerialLocation.mp4" type="video/mp4"/>
            </video>             
            </div>           
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
