import React from 'react'

const Header = () => {
    return (
        <>         
		       <header className="header">
			   <div className="container-fluid bg-warning" onClick={()=>{window.location.href='https://downloads.smallhd.com/'}}>
				 <div className="d-flex align-items-center justify-content-center">
					<div className="p-2 mb-1 bg-warning">
						<a className='text-black'>Please update your monitor to <b className=''>5.5.2</b> firmware before activating
your monitor.</a>
					</div>
					
				</div>
				</div>
			     <div className="container-fluid">
				  <div className="d-flex align-items-center justify-content-center">
					<div className="site-header-logo">
						<a target="_blank" href="https://smallhd.com" className="logo" rel="noreferrer">
							<img src="https://downloads.smallhd.com/images/logo.svg" alt=""/>
						</a>
					</div>
					<div className="navbar navbar-static-top bs-docs-nav">
						<nav id="bs-navbar" role="navigation" aria-label="Main">
							<ul className="nav navbar">
								<li className="products-menu">
									<a target="_blank" rel="noreferrer" href="https://smallhd.com/collections/all-products" className=" text-uppercase" title="Browse all our products">PRODUCTS</a>
								</li>
								<li className="support-menu">
									<a target="_blank" rel="noreferrer" href="https://support.smallhd.com/" className="text-uppercase" title="Customer Support, Knowledge-base, FAQ, downloads and live-chat.">Support</a>
								</li>
								<li className="support-menu">
									<a target="_blank" rel="noreferrer"  href="https://smallhd.com/blogs/community" className="text-uppercase" title="SmallHD News and articles">Community</a>
								</li>
								<li className="support-menu">
									<a target="_blank" rel="noreferrer" href="https://smallhd.com/pages/my-account" className="text-uppercase" title="Your SmallHD account">My account</a>
								</li>
								<li className="search" id="submit">
									<a href="#"><span className="d-none">Search</span></a>
								</li>
							</ul>
						</nav>
					</div>
					<div></div>
				</div>
			</div>
		</header>                
        </>
    )
}

export default Header
